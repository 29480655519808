<template>
  <div>
    <el-form ref="form" status-icon :model="form" :rules="rules">
      <el-row :gutter="20">
        <el-col :span="20">
          <el-form-item prop="name" size="medium">
            <span class="input--label d-block" :class="mode ? 'text__day2' : 'text__night2'">
              {{ $t("message.phone_number") }}
            </span>
            <crm-input
              :type="'tel'"
              :maskFormat="'tel'"
              :placeholder="$t('message.phone_number')"
              :inputValue="form.phone_number"
              v-model="form.phone_number"
              :size="'medium'"
              :class="mode ? 'input__day' : 'input__night'"
            ></crm-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()"> Сохранить </el-button>
      <el-button type="warning" @click="close()"> Закрыть </el-button>
    </span>
  </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import dialog from "@/utils/mixins/dialog";
import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [dialog],
  data() {
    return {
      form: {},
    };
  },
  props:{
      guest_id:{
          required:true
      }
  },
  computed: {
    ...mapGetters({
      rules: "guestPhoneNumber/rules",
      model: "guestPhoneNumber/model",
      columns: "guestPhoneNumber/columns",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      save: "guestPhoneNumber/store",
      empty: "guestPhoneNumber/empty",
      guestPhoneNumberInventory: "guestPhoneNumber/inventory",
    }),
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid && !this.loadingButton && this.guest_id) {
          this.loadingButton = true;
          this.form.guest_id=this.guest_id;
          this.save(this.form)
            .then((res) => {
              this.$alert(res);
              if (res.status == 201) {                               
                this.form = JSON.parse(
                  JSON.stringify({
                    phone_number: "",
                  })
                );
                this.guestPhoneNumberInventory();
                this.$parent.$parent.dialogCreatePhoneNumber = false;
              }
              this.loadingButton = false;
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    close() {
      this.$parent.$parent.dialogCreatePhoneNumber = false;
    },
  },
};
</script>
