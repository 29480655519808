<template>
  <div>
    <el-form ref="form" status-icon :model="form" :rules="rules">
      <el-form-item prop="name" size="medium">
        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
          {{ $t("message.company") }}
        </span>
        <crm-input
          :class="mode ? 'input__day' : 'input__night'"
          :inputValue="form.name"
          v-model="form.name"
          :placeholder="$t('message.company')"
        ></crm-input>
      </el-form-item>
      <el-form-item prop="name" size="medium">
        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
          {{ $t("message.phone_number") }}
        </span>
        <crm-input
          :class="mode ? 'input__day' : 'input__night'"
          :type="'tel'"
          :maskFormat="'tel'"
          :placeholder="$t('message.phone_number')"
          :inputValue="form.phone_number"
          v-model="form.phone_number"
          :size="'medium'"
        ></crm-input>
      </el-form-item>
      <el-form-item prop="name" size="medium">
        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
          {{ $t("message.address") }}
        </span>
        <el-input :class="mode ? 'input__day' : 'input__night'"
          type="textarea"
          :placeholder="$t('message.address')"
          v-model="form.address">
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()" v-loading="loadingButton">
        Сохранить
      </el-button>
      <el-button type="warning" @click="close()"> Закрыть </el-button>
    </span>
  </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import dialog from "@/utils/mixins/dialog";
import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [dialog],
  data() {
    return {
      form: {},
      loadingButton: false,
    };
  },
  props: {
    guest_id: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      rules: "guestCompany/rules",
      model: "guestCompany/model",
      columns: "guestCompany/columns",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      save: "guestCompany/store",
      empty: "guestCompany/empty",
      guestCompany: "guestCompany/inventory",
    }),
    submit() {        
      this.$refs["form"].validate((valid) => {
        if (valid && !this.loadingButton && this.guest_id) {
          this.loadingButton = true;
          this.form.guest_id = this.guest_id;
          this.save(this.form)
            .then((res) => {
              this.$alert(res);
              if (res.status == 201) {
                this.$parent.$parent.form.guest_company_id =
                  res.data.result.data.guest_company_id;

                this.form = JSON.parse(
                  JSON.stringify({
                    name: "",
                    phone_number: null,
                    address: "",
                  })
                );
                this.guestCompany();
                this.$parent.$parent.dialogCreateGuestCompany = false;
              }
              this.loadingButton = false;
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    close() {
      this.$parent.$parent.dialogCreateGuestCompany = false;
    },
  },
};
</script>
