<template>
  <div class="p-pegas">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                {{ $t("message.visitor") }}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    v-model="filterForm.search"
                    :class="mode ? 'input__day' : 'input__night'"
                    :icon="'el-icon-search'"
                    class="mr-2"
                  ></crm-input>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12" class="flex-style text-right">
            <div class="ml-2" style="float: right">
              <crm-create-and-column-settings
                :permission="$options.name"
                :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                @c-create="drawerCreate = true"
                :columns="columns"
                @c-change="updateColumn"
                :v_can_create="'VisitorController@store'"
              >
              </crm-create-and-column-settings>
            </div>
            
            <export-excel class="btn excel_btn" style="display:inline-flex" :data="list" @fetch="controlExcelData()" :fields="excel_fields" :worksheet="$t('message.visitor')" :name="$t('message.visitor')">
              <el-button size="mini">
                <i class="el-icon-document-delete"></i> {{$t("message.excel")}}
              </el-button>
            </export-excel>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->
      <div class="card-table-header table-crm-smart">
        <table class="table-my-code table-bordered" v-loading="loadingData" :class="mode ? 'table__myday' : 'table__mynight'">
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.full_name.show">
                {{ columns.full_name.title }}
              </th>

              <th v-if="columns.company.show">
                {{ columns.company.title }}
              </th>

              <th v-if="columns.car.show">
                {{ columns.car.title }}
              </th>
              <th v-if="columns.comment.show">
                {{ columns.comment.title }}
              </th>

              <th v-if="columns.phone_number.show">
                {{ columns.phone_number.title }}
              </th>

              <th v-if="columns.guest_coming_reason.show">
                {{ columns.guest_coming_reason.title }}
              </th>

              <th v-if="columns.visit_date.show">
                {{ columns.visit_date.title }}
              </th>

              <!-- <th v-if="columns.visit_time.show">
                {{ columns.visit_time.title }}
              </th> -->

              <th v-if="columns.left_date.show">
                {{ columns.left_date.title }}
              </th>
              <!-- <th v-if="columns.left_time.show">
                {{ columns.left_time.title }}
              </th> -->

              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>
              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>

              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :placeholder="columns.id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.full_name.show">
                <select-guest
                  size="mini"
                  :id="filterForm.guest_id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.guest_id"
                ></select-guest>
              </th>

              <th v-if="columns.company.show">
                <select-guest-company
                  size="mini"
                  v-model="filterForm.guest_company_id"
                  :id="filterForm.guest_company_id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.company.title"
                >
                </select-guest-company>
              </th>

              <th v-if="columns.car.show">
                <select-guest-car
                  size="mini"
                  v-model="filterForm.guest_car_id"
                  :id="filterForm.guest_car_id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.car.title"
                >
                </select-guest-car>
              </th>
              <th v-if="columns.comment.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.comment"
                  :placeholder="columns.comment.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.phone_number.show">
                <select-guest-phone-number
                  size="mini"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.guest_phone_number_id"
                  :id="filterForm.guest_phone_number_id"
                  :placeholder="columns.phone_number.title"
                >
                </select-guest-phone-number>
              </th>

              <!-- <th v-if="columns.guest_coming_reason.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.guest_coming_reason"
                  :placeholder="columns.guest_coming_reason.title"
                  class="id_input"
                ></el-input>
              </th> -->
              <th v-if="columns.staff.show">
                <select-staff
                  :size="'mini'"
                  :id="filterForm.staff_id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.staff_id"
                  :placeholder="$t('message.guest_coming_reason')"
                ></select-staff>
              </th>

              <th v-if="columns.visit_date.show">
                <crm-date-picker
                  :placeholder="columns.visit_date.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.visiting_date"
                  :date="filterForm.visiting_date"
                  size="mini"
                >
                </crm-date-picker>
              </th>

              <!-- <th v-if="columns.visit_time.show">
                <el-time-picker
                  value-format="HH:mm:ss"
                  format="HH:mm:ss"
                  :placeholder="columns.visit_time.title"
                  v-model="filterForm.visiting_time"
                  style="width: 100%"
                  size="mini"
                ></el-time-picker>
              </th> -->
              <th v-if="columns.left_date.show">
                <crm-date-picker
                  :placeholder="columns.left_date.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.left_date"
                  :date="filterForm.left_date"
                  size="mini"
                >
                </crm-date-picker>
              </th>

              <!-- <th v-if="columns.left_time.show">
                <el-time-picker
                  value-format="HH:mm:ss"
                  format="HH:mm:ss"
                  :placeholder="columns.left_time.title"
                  v-model="filterForm.left_time"
                  style="width: 100%"
                  size="mini"
                ></el-time-picker>
              </th> -->

              <th v-if="columns.created_at.show">
                <crm-date-picker
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                  size="mini"
                >
                </crm-date-picker>
              </th>
              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  :placeholder="columns.updated_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.updated_at"
                  size="mini"
                >
                </crm-date-picker>
              </th>
              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr
              v-for="visitor in list"
              :key="visitor.id"
              class="cursor-pointer"
              >
              <td v-if="columns.id.show">{{ visitor.id }}</td>
              <td v-if="columns.full_name.show">
                {{ visitor.full_name }}
              </td>

              <td v-if="columns.company.show">
                {{ visitor.guest_company?visitor.guest_company.name:'' }}
              </td>

              <td v-if="columns.car.show">
                {{
                  visitor.guest_car
                    ? visitor.guest_car.model_with_trashed.model +
                      "-" +
                      visitor.guest_car.number
                    : ""
                }}
              </td>
              <td v-if="columns.comment.show">
                {{visitor.comment}}
              </td>
              <td v-if="columns.phone_number.show">
                {{ visitor.guest_phone_number.phone_number }}
              </td>
              <td v-if="columns.guest_coming_reason.show">
                {{  visitor.staff?visitor.staff.name:'' }} {{  visitor.staff?visitor.staff.last_name:'' }}
              </td>
              <td v-if="columns.visit_date.show">
                {{ visitor.visiting_date }} {{ visitor.visiting_time }}
              </td>
              <!-- 
              <td v-if="columns.visit_time.show">
                {{ visitor.visiting_time }}
              </td> -->
              <td v-if="columns.left_date.show">
                {{ visitor.left_date }}
                <el-button
                  type="success"
                  size="mini"
                  icon="el-icon-time"
                  round
                  @click="updateLeftDateAndTime(visitor)"
                >
                  {{ visitor.left_time }}
                </el-button>
              </td>
              <!-- <td v-if="columns.left_time.show">
                <el-button
                  type="success"
                  size="mini"
                  icon="el-icon-time"
                  round
                  @click="updateLeftDateAndTime(visitor)"
                >
                  {{ visitor.left_time }}
                </el-button>
              </td> -->
              <td v-if="columns.created_at.show">{{ visitor.created_at }}</td>
              <td v-if="columns.updated_at.show">{{ visitor.updated_at }}</td>
              <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :name="$options.name"
                  :model="visitor"
                  :actions="actions"
                  :permissionShow="'VisitorController@update'"
                  :permissionDestroy="'VisitorController@destroy'"
                  @edit="edit"
                  @delete="destroy"
                ></crm-settings>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>
    </div>

    <div class="app-modal app-modal__full">
      <el-drawer
        :with-header="false"
        :wrapperClosable="false"
        :visible.sync="drawerCreate"
        size="90%"
        ref="drawerCreate"
        @opened="drawerOpened('drawerCreateChild')"
        @closed="drawerClosed('drawerCreateChild')"
      >
        <crm-create ref="drawerCreateChild" drawer="drawerCreate"></crm-create>
      </el-drawer>
      <el-drawer
        :with-header="false"
        :wrapperClosable="false"
        :visible.sync="drawerUpdate"
        size="70%"
        ref="drawerUpdate"
        @opened="drawerOpened('drawerUpdateChild')"
        @closed="drawerClosed('drawerUpdateChild')"
      >
        <crm-update
          :selectedItem="selectedItem"
          ref="drawerUpdateChild"
          drawer="drawerUpdate"
        ></crm-update>
      </el-drawer>
    </div>

    <el-dialog
      title="Время отправления"
      :visible.sync="openDialog"
      width="30%"
      center
      @open="elDialogOpened()"
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
    >
      <el-form ref="form" status-icon :model="form" :rules="visitorRules">
        <span>
          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
            {{ columns.left_date.title }}
          </span>
          <el-form-item prop="left_date" size="medium">
            <crm-date-picker
              :placeholder="columns.left_date.title"
              :date="form.left_date"
              v-model="form.left_date"
              style="width: 100%"
              :class="mode ? 'input__day' : 'input__night'"
            ></crm-date-picker>
          </el-form-item>
        </span>
        <span>
          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
            {{ columns.left_time.title }}
          </span>
          <el-form-item prop="left_time" size="medium">
            <el-time-picker
              :placeholder="columns.left_time.title"
              v-model="form.left_time"
              value-format="HH:mm:ss"
              format="HH:mm:ss"
              style="width: 100%"
              size="mini"
              :class="mode ? 'input__day' : 'input__night'"
            ></el-time-picker>
          </el-form-item>
        </span>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="updateVisitor()">
          Сохранить
        </el-button>
        <el-button type="warning" @click="closeElDialog()"> Закрыть </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import moment from "moment";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import selectGuest from "@/components/filters/inventory/select-guest";
import selectStaff from "@/components/filters/inventory/select-staff";
import selectGuestPhoneNumber from "@/components/filters/inventory/select-guest-phone-number";
import selectGuestCar from "@/components/filters/inventory/select-guest-car";
import selectGuestCompany from "@/components/filters/inventory/select-guest-company";
import list from "@/utils/mixins/list";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  mixins: [list],
  name: "contract",
  components: {
    CrmCreate,
    selectGuest,
    selectStaff,
    selectGuestPhoneNumber,
    selectGuestCar,
    selectGuestCompany,
    CrmUpdate,
  },
  data() {
    return {
      drawer: false,
      openDialog: false,
      currentPage2: 5,
      checkList: [],
      form: {
        left_date: null,
        left_time: null,
      },
      sarcheBlocks: "",
      selectedVisitor: null,
      loadingButton: false,
      visitorRules: {
        left_date: [
          {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "change",
          },
        ],
        left_time: [
          {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "change",
          },
        ],
      },
    };
  },
  watch: {
    "form.left_date": {
      handler: function () {},
      immediate: true,
      deep: true,
    },
    "form.left_time": {
      handler: function () {},
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      list: "visitor/list",
      columns: "visitor/columns",
      pagination: "visitor/pagination",
      statues: "visitor/statues",
      filter: "visitor/filter",
      sort: "visitor/sort",
      mode: "MODE"
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "visitor/index",
      setPagination: "visitor/setPagination",
      updateSort: "visitor/updateSort",
      updateFilter: "visitor/updateFilter",
      updateColumn: "visitor/updateColumn",
      updatePagination: "visitor/updatePagination",
      show: "visitor/show",
      empty: "visitor/empty",
      delete: "visitor/destroy",
      refreshData: "visitor/refreshData",
      updateDates: "visitor/updateDates",
    }),
    elDialogOpened() {
      if (
        this.selectedVisitor.left_date == null &&
        this.selectedVisitor.left_time == null
      ) {
        this.form.left_date = moment().format("DD.MM.YYYY");
        this.form.left_time = moment().format("HH:mm:ss");
      } else {
        this.form.left_date = this.selectedVisitor.left_date;
        this.form.left_time = this.selectedVisitor.left_time;
      }
    },
    updateLeftDateAndTime(visitor) {
      this.selectedVisitor = visitor;
      this.form.id = visitor.id;
      this.openDialog = true;
    },
    updateVisitor() {
      this.$refs["form"].validate((valid) => {
        if (valid && !this.loadingButton) {
          this.loadingButton = true;
          this.updateDates(this.form)
            .then((res) => {
              this.$alert(res);
              if (res.status == 201) {
                this.closeElDialog();
              }
              this.fetchData();
              this.afterFetchData();
              this.loadingButton = false;
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    closeElDialog() {
      this.openDialog = false;
      this.form = JSON.parse(
        JSON.stringify({
          left_date: null,
          left_time: null,
        })
      );
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$alert(res);
          this.fetchData();
        })
        .catch((err) => {
          this.$alert(err);
        });
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
          if (this.columns.hasOwnProperty(key)) {
              let column = this.columns[key];
              if (column.show && column.column != 'settings' && column.column != 'left_time' && column.column != 'visit_time') {                
                  switch (column.column) {                      
                      case 'company' : 
                        this.excel_fields[column.title] = {                            
                            callback: (item) => {
                                return item.guest_company ? item.guest_company.name : "";
                            }
                        };
                        break;                      
                      case 'car' : 
                        this.excel_fields[column.title] = {
                            field: 'guest_car',
                            callback: (guest_car) => {
                                return guest_car ? (guest_car.model_with_trashed.model + "-" + guest_car.number) : "";
                            }
                        };
                        break;
                      case 'phone_number':
                          this.excel_fields[column.title] = {
                              field: 'guest_phone_number',
                              callback: (guest_phone_number) => {
                                  return `Тел: ${guest_phone_number.phone_number}`
                              }
                          }; 
                          break;
                      case 'guest_coming_reason':
                        this.excel_fields[column.title] = {
                            field: 'staff',
                            callback: (staff) => {
                                return staff ? (staff.name + " " + staff.last_name) : "";
                            }
                        };
                        break;
                      case 'visit_date':
                        this.excel_fields[column.title] = {                            
                            callback: (item) => {
                                return (item.visiting_date ? item.visiting_date : '') + ' ' + (item.visiting_time ? item.visiting_time : '');
                            }
                        };
                        break;
                      case 'left_date':
                        this.excel_fields[column.title] = {                            
                            callback: (item) => {
                                return (item.left_date ? item.left_date : '') + ' ' + (item.left_time ? item.left_time : '');
                            }
                        };
                        break;
                      default:
                        this.excel_fields[column.title] = column.column;
                        break;
                  }
              }
          }
      }
    }
  },
};
</script>

