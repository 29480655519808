<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
            {{
              $t("message.new_m", {
                m: $t("message.visitor"),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit()"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
          <el-form ref="form" status-icon :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-row :gutter="20">
                  <el-col :span="20">
                    <el-form-item prop="guest_id" size="medium">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                        {{ $t("message.guest") }}
                      </span>
                      <select-guest
                        :size="'medium'"
                        :id="form.guest_id"
                        v-model="form.guest_id"
                      ></select-guest>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->
                  <el-col :span="4">
                    <div class="pulus mts" @click="dialogCreateGuest = true" :class="mode ? 'pulus__myday' : 'pulus__mynight'">
                      <i class="el-icon-plus"></i>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
              <!-- end col -->
              <el-col :span="12">
                <el-row :gutter="20">
                  <el-col :span="20">
                    <el-form-item size="medium">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                        {{ $t("message.guest") }} {{ $t("message.company") }}
                      </span>
                      <select-guest-company
                        :size="'medium'"
                        @refreshGuestId="changeGuestId"
                        :guest_id="form.guest_id"
                        :id="form.guest_company_id"
                        v-model="form.guest_company_id"
                      ></select-guest-company>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->
                  <el-col :span="4">
                    <div
                      class="pulus mts"
                      @click="dialogCreateGuestCompany = true"
                      :class="mode ? 'pulus__myday' : 'pulus__mynight'"
                      
                    >
                      <i class="el-icon-plus"></i>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
              <!-- end col -->
              <el-col :span="12">
                <el-row :gutter="20">
                  <el-col :span="20">
                    <el-form-item prop="guest_phone_number_id" size="medium">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                        {{ $t("message.phone_number") }}
                      </span>
                      <select-guest-phone-number
                        :size="'medium'"
                        :guest_id="form.guest_id"
                        :id="form.guest_phone_number_id"
                        v-model="form.guest_phone_number_id"
                        @refreshGuestId="changeGuestId"
                      ></select-guest-phone-number>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->
                  <el-col :span="4">
                    <div
                      class="pulus mts"
                      @click="dialogCreatePhoneNumber = true"
                      :class="mode ? 'pulus__myday' : 'pulus__mynight'"
                    >
                      <i class="el-icon-plus"></i>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
              <!-- end col -->

              <el-col :span="12">
                <el-row :gutter="20">
                  <el-col :span="20">
                    <el-form-item prop="guest_car_id" size="medium">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                        {{ $t("message.car") }}
                      </span>
                      <select-guest-car
                        :size="'medium'"
                        :guest_id="form.guest_id"
                        :id="form.guest_car_id"
                        v-model="form.guest_car_id"
                        @refreshGuestId="changeGuestId"
                      ></select-guest-car>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->
                  <el-col :span="4">
                    <div class="pulus mts" @click="dialogCreate = true" :class="mode ? 'pulus__myday' : 'pulus__mynight'">
                      <i class="el-icon-plus"></i>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="staff_id" size="medium">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                    {{ $t("message.guest_coming_reason") }}
                  </span>
                  <select-staff
                    :placeholder="$t('message.guest_coming_reason')"
                    :size="'medium'"
                    :id="form.staff_id"
                    v-model="form.staff_id"
                  >
                  </select-staff>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <!-- <el-col :span="12">
                <el-row :gutter="20">
                  <el-col :span="20">
                    <el-form-item prop="guest_coming_reason_id" size="medium">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                        {{ $t("message.guest_coming_reason") }}
                      </span>
                      <select-guest-coming-reason
                        :size="'medium'"
                        :id="form.guest_coming_reason_id"
                        v-model="form.guest_coming_reason_id"
                      ></select-guest-coming-reason>
                    </el-form-item>
                  </el-col> -->
              <!-- end col -->
              <!-- <el-col :span="4">
                    <div
                      class="pulus mts"
                      @click="dialogCreateComingReason = true"
                    >
                      <i class="el-icon-plus"></i>
                    </div>
                  </el-col>
                </el-row>
              </el-col> -->
              <!-- end col -->
              <el-col :span="12">
                <el-form-item prop="visiting_date" size="medium">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                    {{ $t("message.visit_date") }}
                  </span>
                  <crm-date-picker
                    :date="form.visiting_date"
                    size="medium"
                    :placeholder="$t('message.visit_date')"
                    v-model="form.visiting_date"
                    :class="mode ? 'input__day' : 'input__night'"
                  >
                  </crm-date-picker>
                </el-form-item>
              </el-col>

              <!-- end col -->
              <el-col :span="12">
                <el-form-item prop="visiting_time" size="medium">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block" style="line-height: 24px">
                    {{ $t("message.visit_time") }}
                  </span>
                  <el-time-picker
                    value-format="HH:mm:ss"
                    format="HH:mm:ss"
                    size="medium"
                    :placeholder="$t('message.visit_time')"
                    v-model="form.visiting_time"
                    style="width: 100%"
                    :class="mode ? 'input__day' : 'input__night'"
                  ></el-time-picker>
                </el-form-item>
              </el-col>
              
              <!-- end col -->
              <el-col :span="12">
                <!-- <el-form-item  size="medium">   -->
                <select-hidden-company
                  :size="'medium'"
                  :id="form.company_id"
                  v-model="form.company_id"
                  @dataLength="companyLength"
                ></select-hidden-company>
                <!-- </el-form-item> -->
              </el-col>
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.comment") }}
                  </span>
                  <el-form-item prop="comment" class="label_mini">
                   <el-input
                        type="textarea"
                        :placeholder="$t('message.comment')"
                        v-model="form.comment"
                        :class="mode ? 'input__day' : 'input__night'"
                      >
                      </el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
    <el-dialog
      :visible.sync="dialogCreate"
      width="40%"
      :append-to-body="true"
      center
      ref="dialogCreate"
      :title="$t('message.add_car')"
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
    >
      <crm-car-create
        :guest_id="form.guest_id"
        ref="dialogCreateChild"
        dialog="dialogCreate"
      ></crm-car-create>
    </el-dialog>
    <el-dialog
      :title="$t('message.add_guest')"
      :visible.sync="dialogCreateGuest"
      width="40%"
      :append-to-body="true"
      center
      ref="dialogCreateGuest"
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
    >
      <crm-create-guest
        ref="dialogCreateGuestChild"
        dialog="dialogCreateGuest"
      ></crm-create-guest>
    </el-dialog>
    <el-dialog
      :title="$t('message.add_company')"
      :visible.sync="dialogCreateGuestCompany"
      width="40%"
      :append-to-body="true"
      center
      ref="dialogCreateGuestCompany"
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
    >
      <crm-create-guest-company
        :guest_id="form.guest_id"
        ref="dialogCreateGuestCompanyChild"
        dialog="dialogCreateGuestCompany"
      ></crm-create-guest-company>
    </el-dialog>
    <el-dialog
      :title="$t('message.add_phone_number')"
      :visible.sync="dialogCreatePhoneNumber"
      width="40%"
      :append-to-body="true"
      center
      ref="dialogCreatePhoneNumber"
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
    >
      <crm-create-guest-phone-number
        :guest_id="form.guest_id"
        ref="dialogCreatePhoneNumberChild"
        dialog="dialogCreatePhoneNumber"
      ></crm-create-guest-phone-number>
    </el-dialog>
    <el-dialog
      :title="$t('message.guest_coming_reason')"
      :visible.sync="dialogCreateComingReason"
      width="40%"
      :append-to-body="true"
      center
      ref="dialogCreateComingReason"
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
    >
      <crm-create-guest-coming-reason
        :guest_id="form.guest_id"
        ref="dialogCreateComingReasonChild"
        dialog="dialogCreateComingReason"
      ></crm-create-guest-coming-reason>
    </el-dialog>
  </div>
</template>
<script>
import moment from "moment";
import selectGuest from "@/components/filters/inventory/select-guest";
import selectStaff from "@/components/filters/inventory/select-staff";
import selectGuestPhoneNumber from "@/components/filters/inventory/select-guest-phone-number";
import selectGuestCar from "@/components/filters/inventory/select-guest-car";
import selectGuestCompany from "@/components/filters/inventory/select-guest-company";
import selectHiddenCompany from "@/components/filters/inventory/select-or-hidden-company";
import selectGuestComingReason from "@/components/filters/inventory/select-guest-coming-reason";
import CrmCarCreate from "./car/crm-car-create";
import CrmCreateGuestCompany from "./crm-create-guest-company";
import CrmCreateGuest from "./crm-create-guest";
import CrmCreateGuestPhoneNumber from "./crm-create-guest-phone-number";
import CrmCreateGuestComingReason from "./crm-create-guest-coming-reason";
import drawer from "@/utils/mixins/drawer";
import dialog from "@/utils/mixins/dialog";
import form from "@/utils/mixins/form";
import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [drawer, form, dialog],
  components: {
    CrmCarCreate,
    CrmCreateGuest,
    selectGuest,
    CrmCreateGuestCompany,
    selectGuestCompany,
    selectGuestPhoneNumber,
    CrmCreateGuestPhoneNumber,
    selectGuestCar,
    selectGuestComingReason,
    CrmCreateGuestComingReason,
    selectStaff,
    selectHiddenCompany,
  },
  data() {
    return {
      dialogCreate: false,
      dialogCreateGuest: false,
      dialogCreateGuestCompany: false,
      dialogCreatePhoneNumber: false,
      dialogCreateComingReason: false,
      company_id: "",
    };
  },

  watch: {
    "form.guest_id": {
      handler() {},
      immediate: true,
      deep: true,
    },
    "form.visit_date": {
      handler() {},
      immediate: true,
      deep: true,
    },
    "form.company_id": {
      handler: async function (newValue, oldValue) {},
      deep: true,
      immediate: true,
    },
    // "form.company_id": {
    //   handler() {},
    //   immediate: true,
    //   deep: true,
    // },
  },
  computed: {
    ...mapGetters({
      rules: "visitor/rules",
      model: "visitor/model",
      columns: "visitor/columns",
      visitor: "visitor/model",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      empty: "visitor/empty",
      save: "visitor/store",
    }),
    changeGuestId(guestId) {
      this.form.guest_id = guestId;
    },
    companyLength(val) {
      this.form.company_id = val;
    },
    afterOpen() {
      this.form.visiting_date = moment().format("DD.MM.YYYY");
      this.form.visiting_time = moment().format("HH:mm:ss");
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid && !this.loadingButton) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.$alert(res);
              if (res.status == 201) {
                this.close();
              }
              this.parent().listChanged();
              this.loadingButton = false;
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    close() {
      this.empty();
      this.$parent.$parent.drawerCreate = false;
    },
  },
};
</script>
