import _ from 'lodash';
export default {
    data() {
        return {
            dialogCreate: false
        }
    },
    methods: {
        parent() {
            return this.$parent.$parent;
        },
        closeDialog() {
            if (this.$refs[dialog] && _.isFunction(this.$refs[dialog].closeDialog)) {
                this.$refs[dialog].closeDialog();
            }
        },
        dialogOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        close() {
            this.form = JSON.parse(JSON.stringify({}));
            this.parent().dialogCreate = false
            this.parent().dialogCreateModel = false
        }
    },
}
